<template>
  <div>
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast :key="'toast' + toast.text + index" :show="true" :header="toast.header === '' ? null : toast.header">
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CRow>
           <CCol class="col-lg-4 col-md-4 col-12">
        <CCard>
          <CCardBody>
            <CRow col="12" class="justify-content-between align-items-center">
              <CCol col="12">
                <CInput v-model="pricePerMinute" @input="calculation" vertical type="text" description="0 Dan büyük bir değer olmalıdır."
                  label="1 Dakikanın karşılığı olan TL tutarını giriniz">
                  <template #prepend-content>
                    <CIcon name="cil-money" />
                  </template>
                </CInput>
                <CCol>
                  <CButton v-if="showPriceSaveButton" @click="changePricePerMinute" class="col-12" color="info">
                    <CIcon name="cil-pencil" />
                    ONAYLA
                  </CButton>
                </CCol>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-4 col-12">
        <CCard>
          <CCardBody>
            <CDataTable :loading="isDataLoading" class="mb-0 table-outline" hover :items="minsPerDayItems"
              :fields="minsPerDayFields" head-color="light" :items-per-page="10">
              <template #no-items-view>
                <CRow>
                  <CCol class="col-12 font-3xl text-center">
                    <CIcon name="cil-x" />
                    Veri Bulunamadı
                  </CCol>
                </CRow>
              </template>
              <td slot="number" slot-scope="{item}">
                <div>{{ item.text }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="manage" slot-scope="{item}">
                <CButton @click="deleteMinsPerDayFromListAlert(item)" color="danger">
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </CDataTable>
            <CInput v-model="MinsPerDayToList" type="text" placeholder="Dakika Ekleyin" class='mt-4'>
              <template #append>
                <CButton :disabled="!validateMinPerDay" @click="addMinsPerDayToList" color="success">Ekle
                </CButton>
              </template>
            </CInput>
            <c-badge class='col-12 mt-1'
              v-if='MinsPerDayToList !== "" && (MinsPerDayToList < 1 || MinsPerDayToList > 1440)' color="danger">Minimum
              1, Maksimum 1440.
            </c-badge>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-4 col-12">
        <CCard>
          <CCardBody>
            <CDataTable :loading="isDataLoading" class="mb-0 table-outline" hover :items="dayPerWeekItems"
              :fields="dayPerWeekFields" head-color="light" :items-per-page="10">
              <template #no-items-view>
                <CRow>
                  <CCol class="col-12 font-3xl text-center">
                    <CIcon name="cil-x" />
                    Veri Bulunamadı
                  </CCol>
                </CRow>
              </template>
              <td slot="dayperweek" slot-scope="{item}">
                <div>{{ item.text }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="manage" slot-scope="{item}">
                <CButton @click="deleteDayPerWeekFromListAlert(item)" color="danger">
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </CDataTable>
            <CInput v-model="dayPerWeekToList" type="text" placeholder="Gün Ekleyin" class='mt-4'>
              <template #append>
                <CButton :disabled="!validateDayPerWeek" @click="addDayPerWeekToList" type="submit" color="success">Ekle
                </CButton>
              </template>
            </CInput>
            <c-badge class='col-12 mt-1' v-if='dayPerWeekToList !== "" && (dayPerWeekToList < 1 || dayPerWeekToList > 7)'
              color="danger">Minimum 1, Maksimum 7.
            </c-badge>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-12">
        <CCard>
          <CCardBody>
            <CDataTable :loading="isDataLoading" class="mb-0 table-outline" hover :items="packageDurationPossibilities"
              :fields="packetOptionsFields" head-color="light" :items-per-page="10">
              <template #no-items-view>
                <CRow>
                  <CCol class="col-12 font-3xl text-center">
                    <CIcon name="cil-x" />
                    Veri Bulunamadı
                  </CCol>
                </CRow>
              </template>
              <td slot="durationValue" slot-scope="{item}">
                <div>{{ item.name }} Ay</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="shortDescription" slot-scope="{item}">
                <div>{{ item.description }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="shortDescription" slot-scope="{item}">
                <div>{{ item.subscriptionDayOptions.dayOption }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="shortDescription" slot-scope="{item}">
                <div>{{ item.subscriptionMinutesPerDayOptions.minutesPerDayOption }}</div>
                <div class="small text-muted">
                </div>
              </td>
              <td slot="discount" slot-scope="{item}">
                <div>{{ item.pricePerMint }}</div>
              </td>
              <td slot="discount" slot-scope="{item}">
                <div>{{ item.totalDiscounted }}</div>
              </td>
              <td slot="discount" slot-scope="{item}">
                <div>{{ item.totalMinutes }}</div>
              </td>
              <td slot="discount" slot-scope="{item}">
                <div>{{ item.discountRate }}%</div>
              </td>
              <td slot="manage" slot-scope="{item}">
                <CButton @click="deletePackageDurationFromListAlert(item.id)" color="danger" class="mb-1 ml-1">
                  <CIcon name="cil-trash" />
                </CButton>
                <CButton v-if="item.Status === 'In-active'"  @click="updatePackageStatus(item.id)" color="success" class="mb-1 ml-1">
                  <CIcon name='cil-CheckCircle' />
                </CButton>
                <CButton v-if="item.Status === 'Active'"  @click="updatePackageStatus(item.id)" color="danger" class="mb-1 ml-1">
                  <CIcon name='cil-XCircle' />
                </CButton>
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
        <!-- <CCard>
          <CCardHeader><strong>Yeni Ödeme Aralığı Ekle</strong></CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="col-12 col-md-6">
                <CInput
                    v-model="newDurationAsMonth"
                    description="0 dan büyük bir değer olmalıdır."
                />
                <c-badge
                    class='col-12 mt-1'
                    v-if='newDurationAsMonth !== "" && (newDurationAsMonth < 0 || newDurationAsMonth > 12)'
                    color="danger"
                >Minimum 1, Maksimum 12.
                </c-badge>
              </CCol>
              <CCol col="12" md="3">
                <CInput placeholder="Aylık" disabled></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-6 mt-3">
                <CInput
                    v-model="newDiscountRate"
                    label="İndirim Oranı (%)"
                    placeholder="İndirim Oranı (%)"
                    description="0 ile 100 arasında bir değer olmalıdır"
                />
                <c-badge
                    class='col-12 mt-1'
                    v-if='newDiscountRate !== "" && (newDiscountRate < 0 || newDiscountRate > 100)'
                    color="danger"
                >Minimum 1, Maksimum 100.
                </c-badge>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CInput
                    v-model="newDescription"
                    label="Ek Açıklama Yazısı"
                    placeholder="Ek Açıklama Yazısı"
                    description="ex. 3 Aylık İndirimli Paket"
                />
                <c-badge
                    class='col-12 mt-1'
                    v-if='newDescription !== "" && (newDescription.length < 10)'
                    color="danger"
                >Minimum 10 Karakter Giriniz.
                </c-badge>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-6">
                <CButton :disabled="!validateDurationPackage" v-if="validateDurationPackage"
                         @click="addPackageDurationToList" color="success">Yeni Paket Ekle
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard> -->
      </CCol>
    </CRow>
    <CRow>
      <div class="col-12">
        <CCard>
          <CCardHeader><strong>Yeni Ödeme Aralığı Ekle</strong></CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="col-12 col-md-6">
                <CInput @input="calculation" type="number" v-model="newDurationAsMonth"
                  description="0 dan büyük bir değer olmalıdır." />
                <c-badge class='col-12 mt-1'
                  v-if='newDurationAsMonth !== "" && (newDurationAsMonth < 0 || newDurationAsMonth > 12)'
                  color="danger">Minimum 1, Maksimum 12.
                </c-badge>
              </CCol>
              <CCol col="12" md="3">
                <CInput placeholder="Aylık" disabled></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-lg-4 col-md-6 col-12">

                <label>Günlük Dakika Sayısı</label>
                <Select2 v-model="selectedMinsPerDay" :options="minsPerDayPossibilities" name="Günlük Dakika Sayısı"
                  placeholder="Günlük Dakika Sayısı" @change="calculation" @select="mySelectEvent($event)" />
              </CCol>

              <CCol class="col-lg-4 col-md-6 col-12">

                <label>Haftalık Gün Sayısı</label>
                <Select2 v-model="selectedNumberOfDays" :options="dayPossibilities" name="Haftalık Gün Sayısı"
                  placeholder="Haftalık Gün Sayısı" @change="calculation"  @select="onSelectNumberOfDays($event)" />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CInput disabled v-model="totalMinutes" label="Toplam Dakika" 
                  placeholder="Toplam Dakika" />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CInput @input="calculation" label="Dakika Başına Fiyat" v-model="pricePerMinute"
                  placeholder="Dakika Başına Fiyat" />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CInput @input="calculation" v-model="newDiscountRate" label="İndirim Oranı (%)"
                  placeholder="İndirim Oranı (%)" description="0 ile 100 arasında bir değer olmalıdır" />
                <c-badge class='col-12 mt-1'
                  v-if='newDiscountRate !== "" && (newDiscountRate < 0 || newDiscountRate > 100)' color="danger">Minimum
                  1, Maksimum 100.
                </c-badge>
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CInput disabled v-model="discountedAmount" label="İndirimli Tutar" placeholder="İndirimli Tutar" />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CInput disabled v-model="total" label="Toplam" placeholder="Toplam" />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CInput disabled v-model="totalAfterDiscount" label="İndirim Sonrası Toplam"
                  placeholder="İndirim Sonrası Toplam" />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CInput v-model="newDescription" label="Ek Açıklama Yazısı" placeholder="Ek Açıklama Yazısı"
                  description="ex. 3 Aylık İndirimli Paket" />
                <c-badge class='col-12 mt-1' v-if='newDescription !== "" && (newDescription.length < 10)'
                  color="danger">Minimum 10 Karakter Giriniz.
                </c-badge>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-6">
                <CButton :disabled="!validateDurationPackage" v-if="validateDurationPackage"
                  @click="addPackageDurationToList" color="success">Yeni Paket Ekle
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
      <!-- <CCol class="col-12">
        <CCard>
          <CCardHeader>
            <strong>Paketleri Görüntüle</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CSelect
                    vertical
                    label="Günlük Dakika Sayısı"
                    :value.sync="selectedMinsPerDayPossibility"
                    :options="minsPerDayPossibilities"
                    placeholder="Seçim Yapınız"
                    custom
                />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CSelect
                    vertical
                    label="Haftalık Gün Sayısı"
                    :value.sync="selectedDayPossibility"
                    :options="dayPossibilities"
                    placeholder="Seçim Yapınız"
                    custom
                />
              </CCol>
              <CCol class="col-lg-4 col-md-6 col-12">
                <CSelect
                    label="Ay Seçimi"
                    vertical
                    :value.sync="selectedPackageDurationPossibility"
                    :options="computedPackageDurationPossibilities"
                    placeholder="Seçim Yapınız"
                    custom
                />
              </CCol>
            </CRow>
            <CRow class="text-center">
              <CCol class="mt-5 col-12">
                <div class="font-xl"><strong>Ücret:</strong> {{ Prices.totalPrice }} ₺</div>
                <div class="font-xl" v-if="Prices.discountedTotalPrice"><strong>İndirimli Ücret
                  (%{{ Prices.currentDiscount }}):</strong> {{ Prices.discountedTotalPrice }} ₺
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
import { SubscriptionAdministration } from "@/services/api-service";
import Select2 from 'vue3-select2-component';
export default {
  name: 'Packets',
  components: { Select2 },
  mounted() {
    // this.loadPaymentInfo()
    this.loadListSubscriptionDropdownOptions()
  },
  data() {
    return {
      isValid :false,
      totalAfterDiscount: 0,
      total: 0,
      selectedMinsPerDay: null,
      selectedNumberOfDays: null,
      discountedAmount: 0,
      newDurationAsMonth: 1,
      newDiscountRate: 0,
      newDescription: '',
      MinsPerDayToList: '',
      dayPerWeekToList: '',
      isDataLoading: false,
      packageDurationPossibilities: [],
      minsPerDayPossibilities: [],
      dayPossibilities: [],
      pricePerMinute: 0,
      totalMinutes: 0,
      pricePerMinuteFromServer: 0,
      toasts: [],
      selectedMinsPerDayPossibility: null,
      selectedPackageDurationPossibility: null,
      selectedDayPossibility: null,
      minsPerDayItems: [],
      dayPerWeekItems: [],

      minsPerDayFields: [
        { key: 'number', label: 'Günlük Dakika Seçenekleri' },
        { key: 'manage', label: ' ' },
      ],
      dayPerWeekFields: [
        { key: 'dayperweek', label: 'Haftalık Gün Seçenekleri' },
        { key: 'manage', label: ' ' },
      ],
      packetOptionsFields: [
        { key: 'durationValue', label: 'Paket ismi' },
        { key: 'description', label: 'Tanım' },
        { key: 'dayOption', label: 'Haftalık Günler' },
        { key: 'minutesPerDayOption', label: 'Günde Dakika' },
        { key: 'pricePerMint', label: 'Dakika Başına Fiyat' },
        { key: 'discount', label: 'İndirim' },
        { key: 'totalMinutes', label: 'Toplam Dakika' },
        { key: 'totalDiscounted', label: 'Toplam fiyat' },
        { key: 'Status', label: 'Durum' },
        { key: 'manage', label: 'Aksiyon' },
      ],
    }
  },
  computed: {
    validateDurationPackage() {
      return (
        (parseInt(this.newDurationAsMonth) >= 1 && parseInt(this.newDurationAsMonth) <= 12 && parseInt(this.newDiscountRate) >= 0 && parseInt(this.newDiscountRate) < 100 && this.newDescription.length >= 10) && (this.selectedDayPossibility !== null && this.selectedMinsPerDay !== null)
        )
    },
    validateDayPerWeek() {
      return (
        (this.dayPerWeekToList >= 1 && this.dayPerWeekToList <= 7)
      )
    },
    validateMinPerDay() {
      return (
        (this.MinsPerDayToList >= 1 && this.MinsPerDayToList <= 1440)
      )
    },
    computedPackageDurationPossibilities() {
      if (this.packageDurationPossibilities == null) {
        return []
      }
      return this.packageDurationPossibilities.map(f => {
        return {
          ...f,
          label: f.durationValue,
          value: f.durationValue
        }
      })
    },
    showPriceSaveButton() {
      return this.pricePerMinute !== this.pricePerMinuteFromServer
    },

    // Prices() {
    //   if (this.selectedMinsPerDayPossibility === null || this.selectedDayPossibility === null || this.selectedPackageDurationPossibility === null) {
    //     return {
    //       discountedTotalPrice: null,
    //       currentDiscount: null,
    //       totalPrice: 0
    //     }
    //   }
    //   let discountedTotalPrice;
    //   let currentDiscount;
    //   const totalPrice = ((((this.selectedDayPossibility * this.selectedMinsPerDayPossibility) * 4) * this.pricePerMinute) * this.selectedPackageDurationPossibility)
    //   const findPackageDuration = this.packageDurationPossibilities.find(x => x.durationValue === this.selectedPackageDurationPossibility)//TODO: burası düzeltilecek
    //   if (findPackageDuration && findPackageDuration.discount && findPackageDuration.discount > 0) {
    //     discountedTotalPrice = totalPrice * ((100 - findPackageDuration.discount) / 100)
    //     currentDiscount = findPackageDuration.discount
    //   } else {
    //     discountedTotalPrice = null
    //     currentDiscount = null
    //   }
    //   return {
    //     discountedTotalPrice,
    //     currentDiscount,
    //     totalPrice
    //   }
    // }
  },
  methods: {
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    mySelectEvent({ id, text, createdAt, updatedAt }) {
      this.selectedMinsPerDay = id;
      this.selectedMinsPerDayPossibility = Number(text);
      this.calculation();
    },
    onSelectNumberOfDays({ id, text, createdAt, updatedAt }) {
      this.selectedNumberOfDays = id;
      this.selectedDayPossibility = Number(text);
      this.calculation();
    },
    calculation() {
      if (this.newDiscountRate === null || this.newDiscountRate == 0 && (this.selectedMinsPerDayPossibility === null || this.newDiscountRate == 0) && (this.selectedDayPossibility === null || this.selectedDayPossibility === 0) && (this.newDurationAsMonth === null || this.newDurationAsMonth === 0)) {
        return 
      }
      if (this.newDiscountRate !== 0 || this.newDiscountRate !== null) {

        this.total = ((((this.selectedDayPossibility * this.selectedMinsPerDayPossibility) * 4) * this.pricePerMinute) * this.newDurationAsMonth)
       this.totalMinutes =((this.selectedDayPossibility * this.selectedMinsPerDayPossibility) * (4*this.newDurationAsMonth))
        this.discountedAmount = ((this.total * this.newDiscountRate) / 100)
        this.totalAfterDiscount = this.total - this.discountedAmount

      } else {
        this.total = ((((this.selectedDayPossibility * this.selectedMinsPerDayPossibility) * 4) * this.pricePerMinute) * this.newDurationAsMonth)
        this.totalMinutes =((this.selectedDayPossibility * this.selectedMinsPerDayPossibility) * (4*this.newDurationAsMonth))
        this.discountedAmount = 0
        this.totalAfterDiscount = this.total - this.discountedAmount
      }

    },
    showToast: function (text = 'Başarıyla Kaydedildi.', header = 'Bilgi') {
      this.toasts.push({ text, header });
    },
    calculatePrice(minsPerDay, day, duration) {
      if (minsPerDay !== null) {
        this.selectedMinsPerDayPossibility = minsPerDay
      }
      if (day !== null) {
        this.selectedDayPossibility = day
      }
      if (duration !== null) {
        this.selectedPackageDurationPossibility = duration
      }
    },
    async loadListSubscriptionDropdownOptions() {
      this.isDataLoading = true;
      const result = await SubscriptionAdministration.listSubscriptionDropdownOptions()
      console.log(result);
      this.pricePerMinute = [];
      this.pricePerMinuteFromServer = [];
      this.minsPerDayItems = [];
      this.minsPerDayPossibilities = [];
      this.dayPossibilities = [];
      this.dayPerWeekItems = [];
      this.pricePerMinute = result.data.pricePerMinute
      this.pricePerMinuteFromServer = result.data.pricePerMinute

      if (result.data.minutesPerDayPossibilities) {
        result.data.minutesPerDayPossibilities.forEach(d => {
          this.minsPerDayPossibilities.push({
            text: d.minutesPerDayOption,
            id: d.id,
            createdAt: d.createdAt,
            updatedAt: d.updatedAt,
          })
        });
        this.minsPerDayItems = this.minsPerDayPossibilities;
      }
      if (result.data.dayPossibilities) {
        result.data.dayPossibilities.forEach(d => {
          this.dayPossibilities.push({
            text: d.dayOption,
            id: d.id,
            createdAt: d.createdAt,
            updatedAt: d.updatedAt,
          })
        this.dayPerWeekItems = this.dayPossibilities;
        });
      }
      // this.packageDurationPossibilities = result.data.packageDurationPossibilities;
      let array = result.data.packageDurationPossibilities.map(f => {
        return {
          dayOption: f.subscriptionDayOptions.dayOption,
          minutesPerDayOption: f.subscriptionMinutesPerDayOptions.minutesPerDayOption,
          description: f.description,
          discountRate: f.discountRate,
          discountedAmount: f.discountedAmount,
          durationAsMonths: f.durationAsMonths,
          minuteOptionId: f.minuteOptionId,
          name: f.name,
          totalMinutes: f.totalMinutes ? f.totalMinutes : 0,
          pricePerMint: f.pricePerMint,
          total: f.total,
          totalDiscounted: f.totalDiscounted,
          updatedAt: f.updatedAt,
          createdAt: f.createdAt,
          id: f.id,
          Status: f.isActive ? 'Active' : "In-active"
        }
      })
      this.packageDurationPossibilities = array;
      console.log("packageDurationPossibilities",this.packageDurationPossibilities)
      console.log("array",array)
      this.isDataLoading = false;
    },
    async loadPaymentInfo() {
      this.isDataLoading = true;
      const result = await SubscriptionAdministration.listSubscriptionOptionsForManagement()
      this.pricePerMinute = result.data.pricePerMinute
      this.pricePerMinuteFromServer = result.data.pricePerMinute
      this.minsPerDayPossibilities = result.data.minutesPerDayPossibilities
      this.minsPerDayItems = result.data.minutesPerDayPossibilities
      this.dayPossibilities = result.data.dayPossibilities
      this.dayPerWeekItems = result.data.dayPossibilities
      this.packageDurationPossibilities = result.data.packageDurationPossibilities
      this.isDataLoading = false;
    },
    async addMinsPerDayToList() {
      this.isDataLoading = true;
      await SubscriptionAdministration.addMinutesPerDayOptionToSubscriptionPackages(this.MinsPerDayToList);
      this.loadListSubscriptionDropdownOptions();
      // this.minsPerDayItems = [...this.minsPerDayItems, this.MinsPerDayToList].sort((a, b) => a > b)
      this.MinsPerDayToList = '';
      this.isDataLoading = false;
    },
    async addDayPerWeekToList() {
      this.isDataLoading = true;
      await SubscriptionAdministration.addDayOptionToSubscriptionPackages(this.dayPerWeekToList);      
      this.loadListSubscriptionDropdownOptions();
      // this.dayPerWeekItems = [...this.dayPerWeekItems, this.dayPerWeekToList].sort((a, b) => a > b)
      this.dayPerWeekToList = '';
      this.isDataLoading = false;      
    },
    async addPackageDurationToList() {
      if ((this.selectedMinsPerDayPossibility === null || this.newDiscountRate == 0) && (this.selectedDayPossibility === null || this.selectedDayPossibility === 0) && (this.newDurationAsMonth === null || this.newDurationAsMonth === 0)) {
        this.isValid = true;
        return 
      }
      const result = await SubscriptionAdministration.addPackageDurationOptionToSubscriptionPackages(this.newDurationAsMonth + " Months Package", this.newDurationAsMonth, this.newDiscountRate, this.newDescription, this.selectedNumberOfDays, this.selectedMinsPerDay, this.pricePerMinute, this.discountedAmount, this.total, this.totalAfterDiscount,this.totalMinutes)
      this.loadListSubscriptionDropdownOptions();
      // const NewPackageDuration = {
      //   id: result.data.createdPackageDurationId,
      //   durationValue: this.newDurationAsMonth,
      //   discount: this.newDiscountRate,
      //   shortDescription: this.newDescription
      // };
      // this.packageDurationPossibilities = [NewPackageDuration, ...this.packageDurationPossibilities].sort((a, b) => a > b)
      this.newDurationAsMonth = '';
      this.newDiscountRate = '0';
      this.newDescription = '';
    },
    deleteMinsPerDayFromListAlert(value) {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SubscriptionAdministration.removeMinutesPerDayOptionFromSubscriptionPackages(value.text)
          this.minsPerDayItems = this.minsPerDayItems.filter(x => x !== value)
          await this.$swal.fire(
            'Silindi!',
            'İşlem Başarıyla Gerçekleştirildi!',
            'success'
          )
        }
      });
    },
    deleteDayPerWeekFromListAlert(value) {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SubscriptionAdministration.removeDayOptionFromSubscriptionPackages(value.text)
          this.dayPerWeekItems = this.dayPerWeekItems.filter(x => x !== value)
          await this.$swal.fire(
            'Silindi!',
            'İşlem Başarıyla Gerçekleştirildi!',
            'success'
          )
        }
      });
    },
    deletePackageDurationFromListAlert(id) {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SubscriptionAdministration.removePackageDurationOptionFromSubscriptionPackages(id)
          this.packageDurationPossibilities = this.packageDurationPossibilities.filter(x => x.id !== id)
          await this.$swal.fire(
            'Silindi!',
            'İşlem Başarıyla Gerçekleştirildi!',
            'success'
          )
        }
      });
    },
    updatePackageStatus(id) {
      this.$swal({
        title: 'Onayla',
        text: "Seçilen bu paketin durumunu değiştirmek istediğinizden emin misiniz?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Onaylamak',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SubscriptionAdministration.updateStatusSubscriptionPackages(id)
          this.loadListSubscriptionDropdownOptions();
          await this.$swal.fire(
            'Onaylanmış!',
            'Durum başarıyla değiştirildi!',
            'success'
          )
        }
      });
    },
    async changePricePerMinute() {
      try {
        await SubscriptionAdministration.changePricePerMinuteForSubscriptionPackages(this.pricePerMinute)
        this.pricePerMinuteFromServer = this.pricePerMinute
        this.$toast.success("İşlem Başarıyla Gerçekleştirildi.")
      } catch (e) {
      }

    },
    showAlert() {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Silindi!',
            'İşlem Başarıyla Gerçekleştirildi!',
            'success'
          )
        }
      });
    },
  }
}
</script>



